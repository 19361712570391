import plugin_vue3_3OVhzfLDQe from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/@pinia+nuxt@0.4.11_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/kbet/rems/kie-rems-mweb/.nuxt/components.plugin.mjs";
import unhead_hgd10XWJmO from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_sass@1.69.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_vJgzYrmeP1 from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_sass@1.69.5/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_JB8OHc8PZV from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_93UMIBuxFe from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_sass@1.69.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fUEvfpaGmZ from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_Lyg8enwq9G from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/@sidebase+nuxt-auth@0.5.0_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import debug_67KMIa3R7x from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_sass@1.69.5/node_modules/nuxt/dist/app/plugins/debug.js";
import chunk_reload_client_VDSulj7DzT from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.19.3_sass@1.69.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import v_calendar_reOvuQUMeW from "/home/kbet/rems/kie-rems-mweb/src/client/plugins/v-calendar.ts";
import apexcharts_client_OApXKpYeF4 from "/home/kbet/rems/kie-rems-mweb/src/client/plugins/apexcharts.client.ts";
import lifecycle_hook_wrym1KhHIU from "/home/kbet/rems/kie-rems-mweb/src/client/plugins/lifecycle-hook.ts";
import useBootstrap_client_yySSoVS0QR from "/home/kbet/rems/kie-rems-mweb/src/client/plugins/useBootstrap.client.ts";
import vee_validate_sykkdhZOFm from "/home/kbet/rems/kie-rems-mweb/src/client/plugins/vee-validate.ts";
import plugin_eaUQ1MAr1l from "/home/kbet/rems/kie-rems-mweb/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.4.11_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import error_handle_Lhl0DV9Yxv from "/home/kbet/rems/kie-rems-mweb/src/client/plugins/error-handle.ts";
import mobile_handle_YDdrxUOhm2 from "/home/kbet/rems/kie-rems-mweb/src/client/plugins/mobile-handle.ts";
export default [
  plugin_vue3_3OVhzfLDQe,
  components_plugin_KR1HBZs4kY,
  unhead_hgd10XWJmO,
  vueuse_head_polyfill_vJgzYrmeP1,
  router_JB8OHc8PZV,
  prefetch_client_93UMIBuxFe,
  plugin_fUEvfpaGmZ,
  plugin_Lyg8enwq9G,
  debug_67KMIa3R7x,
  chunk_reload_client_VDSulj7DzT,
  v_calendar_reOvuQUMeW,
  apexcharts_client_OApXKpYeF4,
  lifecycle_hook_wrym1KhHIU,
  useBootstrap_client_yySSoVS0QR,
  vee_validate_sykkdhZOFm,
  plugin_eaUQ1MAr1l,
  error_handle_Lhl0DV9Yxv,
  mobile_handle_YDdrxUOhm2
]